import { useContext, useState } from "react";
import { MyContext } from "../data/contextapi";
import InfoIcon from "./InfoIcon";

export default function Samenstling() {
  const {
    type,
    settype,
    setStep,
    width,
    setWidth,
    doorConfig,
    setDoorConfig,
    sidePannel,
    setSidePannel,
    sidePannelConfig,
    setSidePannelConfig,
    sidePannelSize,
    setSidePannelSize,
    stalenPart,
    setStalenPart,
    stalenType,
    setStalenType,
    open,
    inprogress,
    holeWidth,
    setHoleWidth,
  } = useContext(MyContext);

  const doorImages = {
    Taatsdeur: "door_type_1.jpg",
    Schuifdeur: "door_type_2.jpg",
    Scharnier: "door_type_3.jpg",
    "vast-stalen": "door_type_4.jpg",
  };

  const handleStalenPart = (e) => {
    setStalenPart(e.target.value);
  };

  const handleWidthChange = (e) => {
    if (doorConfig === "dubbele" || sidePannel != "geen") {
      setSidePannelSize(calculateSidePanelWidth(holeWidth, e.target.value));
      setWidth(e.target.value);
    } else {
      setWidth(e.target.value);
      setSidePannelSize(e.target.value);
    }

    if (sidePannel == "geen") {
      setHoleWidth(
        doorConfig === "dubbele" ? 2 * e.target.value : e.target.value
      );
    }
  };

  const calculateSidePanelWidth = (totalWidth, doorWidth) => {
    if (
      doorConfig != "dubbele" &&
      (sidePannel === "links" || sidePannel === "rechts")
    ) {
      return totalWidth - Number(doorWidth);
    }

    if (doorConfig == "dubbele" && sidePannel == "geen") {
      return 0;
    }

    if (doorConfig != "dubbele" && sidePannel === "beide") {
      return (totalWidth - Number(doorWidth)) / 2;
    }
    if (
      doorConfig == "dubbele" &&
      (sidePannel == "links" || sidePannel == "rechts")
    ) {
      return totalWidth - Number(doorWidth) * 2;
    }

    if (doorConfig == "dubbele" && sidePannel == "beide") {
      return (totalWidth - Number(doorWidth) * 2) / 2;
    }

    return 0;
  };

  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        {type != "vast-stalen" ? (
          <>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  margin: "0 5%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "bolder",
                  fontSize: "normal",
                }}
              >
                <span>Samenstelling deur</span>
                {/* <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                    width: "auto",
                    padding: "2px 10px",
                    margin: "0 0 0 10px",
                  }}
                >
                  <span
                    className="body-txt"
                    style={{}}
                    onClick={() => {
                      setStep("door");
                    }}
                  >
                    &euro; 1200,00
                  </span>
                </div> */}
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridColumnGap: "10px",
                fontSize: "11px",
                margin: "5%",
                borderBottom: "1px solid #d1d1d1",
                paddingBottom: "15px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  margin: "5px",
                }}
                onClick={() => {
                  if (!inprogress) setDoorConfig("enkele");
                }}
              >
                <div
                  style={{
                    border:
                      doorConfig === "enkele" ? "2px solid black" : "none",

                    borderRadius: "5px",
                    overflow: "hidden",
                    backgroundColor: "white",
                    padding: "10px",
                    boxShadow:
                      doorConfig === "enkele"
                        ? "none"
                        : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
                    aspectRatio: "1/1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <center>
                    <img
                      src={`./images/doortypes/door_type_3.jpg`}
                      style={{
                        maxHeight: "100%",
                        width: "80%",
                        objectFit: "contain",
                      }}
                    />
                  </center>
                  <div
                    style={{ position: "absolute", top: "2px", right: "2px" }}
                  >
                    <input
                      type="radio"
                      className="sm"
                      checked={doorConfig === "enkele"}
                      readOnly
                    />
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    paddingTop: "5px",
                    margin: "10px 0 0 0",
                    backgroundColor:
                      doorConfig === "enkele" ? "black" : "#d7cb6f",
                    color: "white",
                    border: `1px solid ${
                      doorConfig === "enkele" ? "black" : "white"
                    }`,
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "-5px",
                      left: "0",
                      width: "100%",
                      height: "8px",
                      borderRadius: "50px",
                      backgroundColor: "#fff",
                      borderBottom: `1px solid ${
                        doorConfig === "enkele" ? "black" : "white"
                      }`,
                    }}
                  />
                  <span
                    style={{
                      width: "100%",
                      display: "inline-block",
                      flex: 1,
                      textAlign: "center",
                      textTransform: "uppercase",
                      padding: "2px 0",
                    }}
                  >
                    Enkele deur
                  </span>
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  margin: "5px",
                }}
                onClick={() => {
                  if (!inprogress) setDoorConfig("dubbele");
                }}
              >
                <div
                  style={{
                    border:
                      doorConfig === "dubbele" ? "2px solid black" : "none",
                    borderRadius: "10px",
                    boxShadow: "-5px -2px 10px -2px rgba(0,0,0,0.6)",
                    padding: "10px",
                    backgroundColor: "white",
                    aspectRatio: "1/1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <center>
                    <img
                      src={`./images/doortypes/double-door-type.jpg`}
                      style={{
                        maxWidth: "80%",
                        maxHeight: "100%",
                      }}
                    />
                  </center>
                  <div
                    style={{ position: "absolute", top: "2px", right: "2px" }}
                  >
                    <input
                      type="radio"
                      className="sm"
                      checked={doorConfig === "dubbele"}
                      readOnly
                    />
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    margin: "10px 0 0 0",
                    paddingTop: "5px",
                    backgroundColor:
                      doorConfig === "dubbele" ? "black" : "#d7cb6f",
                    color: "white",
                    border: `1px solid ${
                      doorConfig === "dubbele" ? "black" : "white"
                    }`,
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "-5px",
                      left: "0",
                      width: "100%",
                      height: "8px",
                      borderRadius: "50px",
                      backgroundColor: "#fff",
                      borderBottom: `1px solid ${
                        doorConfig === "dubbele" ? "black" : "white"
                      }`,
                    }}
                  />
                  <span
                    style={{
                      width: "100%",
                      display: "inline-block",
                      flex: 1,
                      textTransform: "uppercase",
                      textAlign: "center",
                      padding: "2px 0",
                    }}
                  >
                    Dubbele deur
                  </span>
                </div>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  margin: "5%",
                  paddingTop: "0.5rem",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "bolder",
                  fontSize: "normal",
                }}
              >
                <InfoIcon title={"Samenstelling zijpaneel"} />
                {/* <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                    width: "auto",
                    padding: "2px 10px",
                    margin: "0 0 0 10px",
                  }}
                >
                  <span
                    className="body-txt"
                    style={{}}
                    onClick={() => {
                      setStep("door");
                    }}
                  >
                    &euro; 900,00
                  </span>
                </div> */}
              </div>
            </div>
            <div
              className="door-content-2"
              style={{
                borderBottom: "25px",
              }}
            >
              <div
                className="door-content-2-1"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridColumnGap: "10px",
                  fontSize: "11px",
                  padding: "5%",
                  borderBottom: "2px solid #d1d1d1",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    margin: "5px",
                  }}
                  onClick={() => {
                    if (!inprogress) setSidePannel("links");
                  }}
                >
                  <div
                    style={{
                      border:
                        sidePannel === "links"
                          ? "2px solid black"
                          : "2px solid #d7d7d7",

                      borderRadius: "5px",
                      overflow: "hidden",
                      boxShadow:
                        sidePannel === "links"
                          ? "none"
                          : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
                      aspectRatio: "1/1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ position: "absolute", top: "2px", right: "2px" }}
                    >
                      <input
                        type="radio"
                        className="sm"
                        checked={sidePannel === "links"}
                        readOnly
                      />
                    </div>
                    <center>
                      <img
                        src="./images/doortypes/samenstelling_link.png"
                        style={{
                          maxWidth: "80%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </center>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      margin: "10px 0 0 0",
                      backgroundColor:
                        sidePannel === "links" ? "black" : "#d7cb6f",
                      color: "white",
                      paddingTop: "5px",
                      // border: '2px solid white',
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-5px",
                        left: "0",
                        width: "100%",
                        height: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#fff",
                      }}
                    />
                    <span
                      className="itm-txt"
                      style={{
                        display: "block",
                        wordWrap: "break-word",
                        textAlign: "center",
                        fontWeight: "normal",
                        textTransform: "uppercase",
                      }}
                    >
                      links
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    margin: "5px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    if (!inprogress) setSidePannel("rechts");
                  }}
                >
                  <div
                    style={{
                      border:
                        sidePannel === "rechts"
                          ? "2px solid black"
                          : "2px solid #d7d7d7",
                      borderRadius: "5px",
                      overflow: "hidden",
                      boxShadow:
                        sidePannel === "rechts"
                          ? "none"
                          : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
                      aspectRatio: "1/1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ position: "absolute", top: "2px", right: "2px" }}
                    >
                      <input
                        type="radio"
                        className="sm"
                        checked={sidePannel === "rechts"}
                        readOnly
                      />
                    </div>
                    <center>
                      <img
                        src="./images/doortypes/samenstelling_rechts.png"
                        style={{ maxWidth: "80%", maxHeight: "100%" }}
                      />
                    </center>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      paddingTop: "5px",
                      margin: "10px 0 0 0",
                      backgroundColor:
                        sidePannel === "rechts" ? "black" : "#d7cb6f",
                      color: "white",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-5px",
                        left: "0",
                        width: "100%",
                        height: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#fff",
                      }}
                    />
                    <span
                      className="itm-txt"
                      style={{
                        display: "block",
                        wordWrap: "break-word",
                        textAlign: "center",
                        fontWeight: "normal",
                        textTransform: "uppercase",
                      }}
                    >
                      rechts
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    margin: "5px",
                  }}
                  onClick={() => {
                    if (!inprogress) setSidePannel("beide");
                  }}
                >
                  <div
                    style={{
                      border:
                        sidePannel === "beide"
                          ? "2px solid black"
                          : "2px solid #d7d7d7",
                      borderRadius: "5px",
                      overflow: "hidden",
                      boxShadow:
                        sidePannel === "beide"
                          ? "none"
                          : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
                      aspectRatio: "1/1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ position: "absolute", top: "2px", right: "2px" }}
                    >
                      <input
                        type="radio"
                        className="sm"
                        checked={sidePannel === "beide"}
                        readOnly
                      />
                    </div>
                    <center>
                      <img
                        src="./images/doortypes/samenstelling_beide.png"
                        style={{ maxWidth: "80%", maxHeight: "100%" }}
                      />
                    </center>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      paddingTop: "5px",
                      margin: "10px 0 0 0",
                      backgroundColor:
                        sidePannel === "beide" ? "black" : "#d7cb6f",
                      color: "white",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-5px",
                        left: "0",
                        width: "100%",
                        height: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#fff",
                      }}
                    />
                    <span
                      className="itm-txt"
                      style={{
                        display: "block",
                        wordWrap: "break-word",
                        textAlign: "center",
                        fontWeight: "normal",
                        textTransform: "uppercase",
                      }}
                    >
                      beide
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    margin: "5px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    if (!inprogress) {
                      setSidePannel("geen");
                      setSidePannelSize(width);
                    }
                  }}
                >
                  <div
                    style={{
                      border:
                        sidePannel === "geen"
                          ? "2px solid black"
                          : "2px solid #d7d7d7",
                      borderRadius: "5px",
                      overflow: "hidden",
                      boxShadow:
                        sidePannel === "geen"
                          ? "none"
                          : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
                      aspectRatio: "1/1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ position: "absolute", top: "2px", right: "2px" }}
                    >
                      <input
                        type="radio"
                        className="sm"
                        checked={sidePannel === "geen"}
                        readOnly
                      />
                    </div>
                    <center>
                      <img
                        src="./images/doortypes/samenstelling_geen.png"
                        style={{ maxWidth: "80%", maxHeight: "100%" }}
                      />
                    </center>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      paddingTop: "5px",
                      margin: "10px 0 0 0",
                      backgroundColor:
                        sidePannel === "geen" ? "black" : "#d7cb6f",
                      color: "white",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-5px",
                        left: "0",
                        width: "100%",
                        height: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#fff",
                      }}
                    />
                    <span
                      className="itm-txt"
                      style={{
                        display: "block",
                        wordWrap: "break-word",
                        textAlign: "center",
                        fontWeight: "normal",
                        textTransform: "uppercase",
                      }}
                    >
                      geen
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  margin: "5%",
                  paddingTop: "0.5rem",
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "bolder",
                  fontSize: "normal",
                }}
              >
                <span>Situatie van het vaste paneel</span>
                {/* <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                    width: "auto",
                    padding: "10px",
                    margin: "0 0 0 10px",
                  }}
                >
                  <span
                    className="body-txt"
                    style={{}}
                    onClick={() => {
                      setStep("door");
                    }}
                  >
                    &euro; 0,00
                  </span>
                </div> */}
              </div>
            </div>
            <div
              style={{
                fontSize: "small",
                display: "flex",
                flexDirection: "column",
                margin: "5%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={stalenType == "divider"}
                  readOnly
                  onChange={() => {
                    setStalenType("divider");
                  }}
                />
                <span style={{ marginLeft: "0.5rem" }}>Roomdivider</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={stalenType == "tussen"}
                  readOnly
                  onChange={() => {
                    setStalenType("tussen");
                  }}
                />
                <span style={{ marginLeft: "0.5rem" }}>Tussen 2 wanden</span>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  margin: "5%",
                  paddingTop: "0.5rem",
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "bolder",
                  fontSize: "normal",
                }}
              >
                <span>Aantal panelen</span>
                {/* <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                    width: "auto",
                    padding: "10px",
                    margin: "0 0 0 10px",
                  }}
                >
                  <span
                    className="body-txt"
                    style={{}}
                    onClick={() => {
                      setStep("door");
                    }}
                  >
                    &euro; 0,00
                  </span>
                </div> */}
              </div>
            </div>
            <div
              className="slider-container"
              style={{ display: "flex", alignItems: "center", padding: "5%" }}
            >
              <div style={{ flex: 1 }}>
                <input
                  type="range"
                  min="1"
                  max="4"
                  value={stalenPart}
                  onChange={handleStalenPart}
                  style={{
                    width: "100%",
                    height: "8px",
                    borderRadius: "5px",
                    appearance: "none",
                    background: "#F2F2F3",
                    outline: "none",
                    marginTop: "10px",
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  backgroundColor: "#F2F2F3",
                  position: "relative",
                  width: "6rem",
                  fontSize: "small",
                  borderRadius: "4px",
                  border: "1px solid"
                }}
              >
                <span style={{ marginRight: "10px" }}>{stalenPart}</span>
                <span
                >
                  panelen
                </span>
              </div>
            </div>
          </>
        )}

        {type !== "vast-stalen" && (
          <>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  margin: "5%",
                  paddingTop: "0.5rem",
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "bolder",
                  fontSize: "normal",
                }}
              >
                <InfoIcon title={"Breedte deur"} />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1 }}>
                <input
                  type="range"
                  min={60}
                  max={120}
                  value={width}
                  onChange={handleWidthChange}
                  style={{
                    width: "100%",
                    height: "8px",
                    borderRadius: "5px",
                    appearance: "none",
                    background: "#F2F2F3", // Grey line
                    outline: "none", // Remove default outline
                    marginTop: "10px", // Adjust margin to separate from the text above
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  backgroundColor: "#F2F2F3",
                  position: "relative",
                  width: "5rem",
                  fontSize: "small",
                  border: "1px solid",
                  borderRadius: "4px",
                }}
              >
                <span style={{ marginRight: "10px" }}>{width}</span>
                <span
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  cm
                </span>
              </div>
            </div>
          </>
        )}

        <button
          className="volgende btn"
          onClick={() => {
            setStep("design");
          }}
        >
          Volgende
        </button>
      </div>
    </>
  );
}
